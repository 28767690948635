




















































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  InternalProjectSummaryReportDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "progressReportDetail",
  components: {
    ExportWord,
    AuditBlock,
    AttachmentsView,
  },
})
export default class ProgressReportDetail extends Vue {

  dataId = 0;
  detail: InternalProjectSummaryReportDto = {
    project: {},
  };

  selector = "table-detail";
  filename = "项目总结报告";
  styles = "";

  hostType = AttachmentHostType.Internal_Project_Summary_Report;


  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }


  // 获取详情
  async fetchDetail() {
    await api.internalProjectSummaryReports
      .get({ id: this.dataId })
      .then((res) => {
        this.detail = { ...res };

      });
  }

  get auditApi(){
    return {
      canAudit: api.internalProjectSummaryReports.canAudit,
      userAudit: api.internalProjectSummaryReports.audit
    } as AuditApi
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
