



























































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  OrganizationUnitDto,
  ProjectDto,
  CloseReportCreateOrUpdateDto,
  AttachmentHostType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  components: {
    SimpleUploadImage,
    AbSelect,
    ProjectSelect,
    MultipleUploadFile,
  },
})
export default class CreateExternalProjectCloseReport extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  organizationName: any = "";
  project: ProjectDto[] = [];
  canSelect = false;
  form: CloseReportCreateOrUpdateDto = {
    id: 0,
    projectId: undefined,
    totalBudget: undefined,
    projectManager: undefined,
    assessmentDate: undefined,
    place: undefined,
    projectTeams: undefined,
    startDate: undefined,
    endDate: undefined,
    serviceObjects: undefined,
    serviceArea: undefined,
    assessmentType: undefined,
    problemsCheck: undefined,
    implementReview: undefined,
    situationCheck: undefined,
    interviewCheck: undefined,
    experience: undefined,
    problemsSolution: undefined,
    successfulCase: undefined,
    fundsUsage: undefined,
    financeProblems: undefined,
    savedAttachments: [],
  };

  get hostTypeFile() {
    return AttachmentHostType.External_Project_Close_Report;
  }

  externalProject: any = {
    projectPeriod: undefined,
    objectiveOfMain: undefined,
    serviceGroup: undefined,
  };

  partnerOrgList: OrganizationUnitDto[] = [];

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.externalProjectCloseReport
        .getDetail({ id: Number(this.$route.params.id) })
        .then((res: any) => {
          this.form = { ...res };
          this.organizationName = res.organizationUnit.displayName;
        });
    } else {
      if (this.$route.query.projectId) {
        this.canSelect = true;
        this.form.projectId = Number(this.$route.query.projectId);
        this.getProjectDetail(this.form.projectId);
      }
    }

    api.organizationUnit.getAll().then((res: any) => {
      this.partnerOrgList = res.items;
    });
  }

  selectProject(e: number) {
    this.clearData(e);
    this.getProjectDetail(e);
  }

  getProjectDetail(projectId: number) {
    api.externalProject
      .getDetailByProjectId({ projectId: projectId })
      .then((res: any) => {
        this.organizationName = res.organizationUnit.displayName;
        this.form.startDate = res.startDate;
        this.form.endDate = res.endDate;
      });
  }

  clearData(projectId: number) {
    this.form = {
      id: this.dataId ? this.dataId : 0,
      projectId: projectId,
      totalBudget: undefined,
      projectManager: undefined,
      assessmentDate: undefined,
      place: undefined,
      projectTeams: undefined,
      startDate: undefined,
      endDate: undefined,
      serviceObjects: undefined,
      serviceArea: undefined,
      assessmentType: undefined,
      problemsCheck: undefined,
      implementReview: undefined,
      situationCheck: undefined,
      interviewCheck: undefined,
      experience: undefined,
      problemsSolution: undefined,
      successfulCase: undefined,
      fundsUsage: undefined,
      financeProblems: undefined,
      savedAttachments: [],
    };
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.externalProjectCloseReport.update({
            body: this.form,
          });
        } else {
          await api.externalProjectCloseReport.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("更新成功");
      } else {
        this.$message({
          type: "error",
          message: "请检查表单",
        });
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    projectId: [
      {
        required: true,
        message: "项目必选",
        trigger: "blur",
      },
    ],
    reportDate: [
      {
        required: true,
        message: "提交报告日期必选",
        trigger: "blur",
      },
    ],
  };
}
