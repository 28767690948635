




























































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  CloseReportDetailDto,
  OrganizationUnitDto,
  SummaryReportDetailDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import ExportWord from "@/components/DownloadWord/index.vue";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

@Component({
  name: "progressReportDetail",
  components: {
    ExportWord,
    AuditBlock,
    AttachmentsView,
  },
  filters: {
    formatBudgetCategory(
      budgetCategory: string,
      projectBudgetCategoryList: any[]
    ) {
      let budget: string | undefined;
      if (projectBudgetCategoryList && projectBudgetCategoryList.length > 0) {
        projectBudgetCategoryList.map((item: any) => {
          if (item.value == budgetCategory) {
            budget = item.text;
            return;
          }
        });
      }
      return budget;
    },
  },
})
export default class ProgressReportDetail extends Vue {

  dataId?: number;
  detail: CloseReportDetailDto = {};
  selector = "world-content";
  filename = "结项评估总结报告";
  styles = "";
  hostType = AttachmentHostType.External_Project_Close_Report;
  projectId?: undefined | number;
  summaryReportDetail: SummaryReportDetailDto = {};
  // 项目预算种类 一级
  projectBudgetCategoryList = [];

  // 项目预算种类 二级
  projectBudgetTypeList = [];

  // 资金来源
  projectBudgetCapitalSourceList = [];

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      this.fetchDetail();
    }
    api.enumService
      .getValues({ typeName: "ProjectBudgetCategory" })
      .then((res: any) => {
        this.projectBudgetCategoryList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetType" })
      .then((res: any) => {
        this.projectBudgetTypeList = res;
      });
    api.enumService
      .getValues({ typeName: "ProjectBudgetCapitalSource" })
      .then((res: any) => {
        this.$nextTick(() => {
          this.projectBudgetCapitalSourceList = res;
        });
      });
    // api.dataDictionary.getDataDictionaryListByKey({key: 'EducationType'}).then((res:any) => {
    //   this.educationList = res.items
    // })
  }
  get getBudgetSun() {
    let num = 0;
    if (
      this.summaryReportDetail.externalProjectActualBudgetItems &&
      this.summaryReportDetail.externalProjectActualBudgetItems.length > 0
    ) {
      this.summaryReportDetail.externalProjectActualBudgetItems.map((item) => {
        num += Number(item.budgetQuotation);
      });
    }
    return num;
  }
  // 获取详情
  async fetchDetail() {
    await api.externalProjectCloseReport
      .getDetail({ id: this.dataId })
      .then((res) => {
        this.detail = { ...res };

        this.projectId = res.projectId;
        this.fetchDetailByProjectId();
      });
  }

  // 获取自评详情

  async fetchDetailByProjectId() {
    await api.externalProjectSummaryReport
      .getDetailByProjectId({ projectId: this.projectId })
      .then((res: SummaryReportDetailDto) => {
        if (res) {
          this.summaryReportDetail = res;
        }
      });
  }

  get auditApi(){
    return {
      canAudit: api.externalProjectCloseReport.canAudit,
      userAudit: api.externalProjectCloseReport.audit
    } as AuditApi
  }

  // 返回
  private cancel() {
    this.$router.back();
  }


}
